<template>
  <div class="role">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="角色名称">
					<el-input v-model="formSearch.roleName" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="角色状态">
					<el-select v-model="formSearch.status" clearable>
						<el-option label="启用" value="1"></el-option>
						<el-option label="停用" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属项目">
					<el-select v-model="formSearch.tenantId" clearable>
						<el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker v-model="formSearch.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="handleSearch">检索</el-button>
					<el-button type="success" @click="handleAdd">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="roleName" label="角色名称" width="300"></el-table-column>
					<el-table-column prop="description" label="角色说明" width="100"></el-table-column>
					<el-table-column prop="projectName" label="所属项目"></el-table-column>
					<el-table-column prop="status" label="角色状态" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.status == '0'">启用</span>
							<span v-if="scope.row.status == '1'">停用</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
					<el-table-column prop="" label="操作" width="100">
						<template slot-scope="scope">
							<span @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;" class="color-m">编辑</span>
							|
							<span @click="handDelete(scope.$index, scope.row)" style="color: red;cursor: pointer;">删除</span>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 添加  编辑     弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" @close="dialogVisibleClose">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="角色名称" prop="roleName">
					<el-input v-model="ruleForm.roleName" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="角色说明" prop="description">
					<el-input v-model="ruleForm.description" placeholder="请输入角色说明"></el-input>
				</el-form-item>
				<el-form-item label="角色状态" prop="status">
					<el-select v-model="ruleForm.status">
						<el-option label="启用" value="0"></el-option>
						<el-option label="停用" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属项目" prop="tenantId">
					<el-select v-model="ruleForm.tenantId" placeholder="所属项目">
						<el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色权限">
					<el-tree
						ref="datatree"
						:data="dataMenu"
						show-checkbox
						node-key="id"
						:default-checked-keys="ruleForm.menuIds"
						:props="defaultProps">
					</el-tree>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
export default {
  name: 'role',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			projectList:[],
			formSearch: {
				roleName: '',
				status: '',
				tenantId:'',
				time: null,
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 13, //每页  条数
			totalItems: 0, //列表 总数
			dialogVisible: false,
			dialogTitle: "角色新增",
			dataMenu: [],
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			ruleForm: {
				id: '',
				roleName: '',
				description: '',
				status: '',
				tenantId: '',
				menuIds:[]
			},
		};
  },
  mounted() {}, 
  created() {
		this.getProjectList(); //加载项目
		this.getTreeList();//菜单树列表
		this.getPageList(); //加载列表
  },
  methods: {
		//加载项目
		getProjectList() {
			this.$provider.get('/wg-system/tenant/list', {}).then(res => {
				this.projectList = res.data;
			})
		},
		//点击查询  按钮
		handleSearch() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/role/page', {
				params: {
					name: this.formSearch.name,
					tatus: this.formSearch.status,
					tenantId: this.formSearch.tenantId,
					startTime: this.formSearch.time != null ? this.formSearch.time[0] : "",
					endTime: this.formSearch.time != null ? this.formSearch.time[1] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//删除
		handDelete(index,item){
			if(confirm("确定删除本条数据吗？")){
				this.$provider.get('/wg-system/role/delete', {
					params: {
						id: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('删除失败');
						return false;
					}
				})
			}
		},
		//编辑  弹框打开
		handleEdit(index,item) {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "角色编辑";
			this.ruleForm.id = item.id;
			this.ruleForm.roleName = item.roleName;
			this.ruleForm.description = item.description;
			this.ruleForm.status = item.status;
			this.ruleForm.tenantId = item.tenantId;
			this.ruleForm.menuIds = item.menuId;
		},
		//添加  弹框打开
		handleAdd() {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "角色新增";
			this.ruleForm.id = '';
			this.ruleForm.roleName = '';
			this.ruleForm.description =  '';
			this.ruleForm.status =  '';
			this.ruleForm.tenantId =  '';
		},
		dialogVisibleClose(){
			//清空选中节点  tree
			this.$nextTick(() => {
				this.$refs.datatree.setCheckedKeys([]);
			});
		},
		//菜单树列表
		getTreeList() {
			this.$provider.get('/wg-system/menu/menuTree', {}).then(res => {
				this.dataMenu = res.data;
			})
		},
		//添加  编辑  确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let menuIds = this.$refs.datatree.getCheckedKeys().length!=0?this.$refs.datatree.getCheckedKeys():"";
					if (this.dialogTitle == "角色新增") {
						this.$provider.post('/wg-system/role/insert', {
							roleName: this.ruleForm.roleName,
							description: this.ruleForm.description,
							status: this.ruleForm.status,
							tenantId: this.ruleForm.tenantId,
							menuIds: menuIds,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('添加失败');
								return false;
							}
						})
					} else if (this.dialogTitle == "角色编辑") {
						this.$provider.post('/wg-system/role/update', {
							roleId: this.ruleForm.id,
							roleName: this.ruleForm.roleName,
							description: this.ruleForm.description,
							status: this.ruleForm.status,
							tenantId: this.ruleForm.tenantId,
							menuIds: menuIds,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('编辑失败');
								return false;
							}
						})
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
  }
};
</script>

<style scoped="scoped"></style>
